<template>
<b-card :title="`${$t('create_integration')}`">
  <form @submit.prevent="create" @keydown="form.onKeydown($event)">
    <b-row>
      <b-col sm="6">
        <div class="form-group">
          <label for="name">Назва інтеграції<span class="text-danger">*</span></label>
          <input type="text" class="form-control" v-model="form.name" :class="{ 'is-invalid': form.errors.has('name') }">
          <has-error :form="form" field="name" />
        </div>
      </b-col>
      <b-col sm="6"></b-col>
    </b-row>
    <button type="submit" class="btn btn-success" :disabled="form.busy">{{ $t('add') }}</button>
  </form>
</b-card>
</template>
<script>
import Form from 'vform'
export default {
  middleware: 'auth',
  layout: 'default',
  metaInfo () {
    return { title: this.$t('create_integration') }
  },
  data: () => ({
    form: new Form({
      name: ''
    })
  }),
  methods: {
    async create () {
      this.form.busy = true
      const apiUrl = process.env.VUE_APP_ENDPOINT_V1
      await this.form.post(apiUrl + 'checkbox/integration').then(resp => {
        if (resp.data.status) {
          this.$router.push({ name: 'checkbox' })
          this.form.busy = false
        }
      }).catch(error => {
        this.form.errors.set(error.response.data.error.errors)
      })
    }
  }
}
</script>
